  <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h2>Contact</h2>
          <p>Contactez-nous</p>
        </header>

        <div class="row gy-4">

          <div class="col-lg-6">

            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-geo-alt"></i>
                  <h3>Adresse</h3>
                  <p>Immeuble A16 Centre Urbain Nord <br>1003 Tunis</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Appelez-nous</h3>
                  <p>+216 29 524 328</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>E-mail</h3>
                  <p>Contact@mspforafrica.com<br>Support@mspforafrica.com</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-clock"></i>
                  <h3>Horaires</h3>
                  <p>Lundi - Vendredi<br>9H - 18H</p>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-6">
            <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="php-email-form">
              <div class="row gy-4">

                <div class="col-md-6">
                  <input type="text" name="name" formControlName="name" class="form-control" placeholder="Votre nom" required>
                    <div style="color: red;" *ngIf="myForm.get('name')!.errors?.['required'] && myForm.get('name')!.touched">
                {{"Le nom est requis."}}
              </div>
                </div>

                <div class="col-md-6 ">
                  <input type="email" class="form-control" formControlName="email" name="email" placeholder="Votre e-mail" required>
                <div style="color: red;"
                *ngIf="myForm.get('email')!.errors?.['required'] && myForm.get('email')!.touched">
                {{"E-mail est requis."}}
              </div>
                  </div>

                <div class="col-md-12">
                  <input type="text" class="form-control" formControlName="subject" name="subject" placeholder="Sujet" required>
                 <div style="color: red;"
                *ngIf="myForm.get('subject')!.errors?.['required'] && myForm.get('subject')!.touched">
                {{"Le sujet est obligatoire."}}
              </div>
                  </div>

                <div class="col-md-12">
                  <textarea class="form-control" formControlName="message" name="message" rows="6" placeholder="Message" required></textarea>
                <div style="color: red;"
                *ngIf="myForm.get('message')!.errors?.['required'] && myForm.get('message')!.touched">
                {{"Un message est requis."}}
              </div>
                  </div>

                <div class="col-md-12 text-center">
                  <div class="loading">Chargement</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Votre message a été envoyé. Merci!</div>

                  <button  type="submit">Envoyer le message</button>
                </div>

              </div>
            </form>

          </div>

        </div>

      </div>

    </section><!-- End Contact Section -->
