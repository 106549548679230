import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkService } from 'src/app/Shared/Services/work.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

    myForm: FormGroup;

  constructor(private workService: WorkService,private toastr: ToastrService) { 
  this.myForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      subject: new FormControl(''),
      message: new FormControl('')
    });
  }

  ngOnInit(): void {
  }

reload(){
          if (window.localStorage) {
              if (!localStorage.getItem('reload')) {
                  localStorage['reload'] = true;
                  window.location.reload();
              } else {
                  localStorage.removeItem('reload');
              }
          }
      }
  onSubmit() {
  let data = this.myForm.value;

    let content = {

      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
    };

    this.workService.sendMail(content).subscribe(
      (res) => {
        console.log("Sent !!");
            this.toastr.success('Votre message a bien été envoyé', 'MSP FOR AFRICA');
            this.toastr.success('Votre message a bien été envoyé', 'MSP FOR AFRICA');
            //this.reload();
            this.myForm.reset();

      },
      (err) => {
        console.log("Not Sent !!");
        this.toastr.error('Votre message n a pas été envoyé', 'MSP FOR AFRICA');

      }
    );
    console.log(this.myForm.value);
  }
}
