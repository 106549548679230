import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  constructor(private http: HttpClient) { }

  sendMail(content: any) {
    return this.http.post<any>(`${AppConfig.mailUrl}/sendMessage`, content);
  }
}
